<template>
<div class="lesson" v-loading="listLoading">
  <div class="header">
    <div class="logoAll"  @click="$router.push('./index')">
      <img :src="logo" class="logo" alt="" />
     {{name}}
    </div>
    
    <div class="search">
      <input  v-model="keywords" placeholder="请输入您需要搜索的课程" @keyup.enter="getList('-1')"/>
      <div class="btn" @click="getList(classify)">
        <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BB%84%202.png"/>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="idea-nav" style="justify-content: center">
      <template v-for="(item, index) of category">
        <div class="idea-nav-item" style="padding: 0 60px; margin: 0 30px" :key="item.id" @click="getList(index)" :class="{ active: classify == index }">
          {{ item.name }}
        </div>
      </template>
    </div>
    <van-list v-if="list.length" :finished="finished" finished-text="没有更多了" style="margin-top: 40px">
      <div class="list" v-if="category[classify].id=='train' || category[classify].id=='lesson'" >
        <div class="card" v-for="item of list" :key="item.id" @click="jumpDetail(item)">
          <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="item.is_free==2"/>
              <img :src="$vip" v-else-if="item.is_vip_flat==1"/>
              <img :src="$noVip" v-else>
            </div>
          <div class="content">
            <van-image class="cover" fit="cover" 
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="item.cover_mobile" />
            <div class="detail">
              <p class="title">
                {{ item.name }}
              </p>
              <!-- <p class="text">
                {{ item.description }}
              </p> -->
            </div>
            <div class="handle">
              <div class="btn">立即学习</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-if="category[classify].id=='live'" >
        <div class="card" v-for="item of list" :key="item.id" @click="liveplay(item)">
          
          <div class="content">
            <van-image class="cover" fit="cover" 
            :error-icon="$placeImg"
            :loading-icon="$placeImg"
            :src="item.img_url" />
            <div class="detail">
              <p class="title">
                {{ item.name }}
              </p>
              <p class="text">主讲人：{{ item.host }}</p>
              <!-- <p class="text">
                {{ item.description }}
              </p> -->
            </div>
            <div class="handle">
              <div class="btn">观看回放</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <idea-list v-if="category[classify].id=='idea'" :list="list"></idea-list>
    <van-empty class="emtpyMain" v-if="!list.length" description="暂无数据" />
  </div>
</div>
</template>

<script>
import ideaList from '../components/ideaList.vue';
export default {
  components: { ideaList },
  name: "lesson",
  data() {
    return {
      list: [],
      tag: 1,
      keywords: "",
      classify: 0,
      certificate: [],
      topData: [],
      category: [
        {
          id: "live",
          name: "直播",
        },
        // {
        //   id: "idea",
        //   name: "锦囊",
        // },
      ],
      loading: false,
      pageLoading: false,
      finished: true,
      more: false,
      ideaNavData: [],
      ideaAllData: [],
      listLoading: false,
    };
  },
  mounted() {
    if (this.$store.state.plat_id == '107' || this.$store.state.plat_id == '121') {
        this.category.unshift({
          id: "lesson",
          name: "VIP课程",
        })
      } else{
        this.category.unshift({
          id: "lesson",
          name: "基础课程",
        })
      }
    // if (this.plat == 118 || this.plat == 119) {
    //   this.category.unshift({
    //     id: "train",
    //     name: "培训",
    //   });
    // }
    window.addEventListener("scroll", this.onLoad);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    name() {
      return this.$store.state.plat.name;
    },
    logo() {
      // return this.$store.state.plat.logo;
      return 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png'
    },
  },
  methods: {
    liveplay(item) {
      let {
        name,
        video_url,
        id,
        video_id
      } = item;
      if(video_url || video_id){
        this.$store.commit('SET_VIDEO',null)
          this.$router.push(
            `./player?type=ali&id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(video_url)}&from=直播`
          );
      }else{
          this.$toast('没有视频');
      }
      
      this.$api({
        method: "get",
        url: "/api/livestream/click",
        data: {
          id,
        },
      });
    },
    clear() {
      console.log("clear");
      this.keywords = "";
      this.getList(this.classify);
    },
    async getList(index) {
      console.log(index)
      if(index!='-1')this.classify = index;
      if (!this.keywords) {
        return;
      }
      
      this.listLoading = true;
      // 直播
      if (this.category[this.classify].id == 'live') {
        this.list = [];
        let res = await this.$api({
          method: "get",
          url: "/api/livestream/search",
          data: {
            keywords: this.keywords,
            page: 1,
            limit: 1000,
          },
        });
        this.listLoading = false;
        if (res.code == 0) {
          let target = res.data.filter((e) => {
            console.log(e.flat_id, this.plat);
            return e.flat_id == this.plat;
          })[0];
          console.log(target);
          if (target.res) {
            this.list = target.res;
          }
        } else {
          this.$toast(res.msg);
        }
      } else if(this.category[this.classify].id=='idea'){ //锦囊
          let res = await this.$api({
                url: "/api/silkbag/search",
                data: {
                  keywords: this.keywords,
                },
              });
      
        if (res.code == 0) {
          res.data.forEach(item=>{
            item.updated_at = item.updated_at.slice(0,10)
            item.content = item.content.replace(/<\/?[^>]*>/g, "")
            item.content = item.content.length>100 ? item.content.slice(0,100)+'...' : item.content
          })
          this.list = res.data
        } else {
          this.$toast(res.msg)
        }
        this.listLoading = false
      }else {
        this.list = [];
        let res = await this.$api({
          method: "get",
          url: "/api/users/university/changeList",
          data: {
            type: this.category[this.classify].id=='train' ? 1 : 2,
            keywords: this.keywords,
            is_interior: 2,
            page: 1,
            limit: 1000,
            flat_id: this.plat,
          },
        });
        this.listLoading = false;
        if (res.code == 0) {
          this.list = res.data.list;
        } else {
          this.$toast(res.msg);
        }
      }
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.logoAll{
  display: flex;
  align-items: center;

  img{
    margin-right: 5px;
  }
}
.header{
  display: flex;
  justify-content: space-between;
  .search{
    flex:0 0 400px;
  }
}
.lesson {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: flex-start;
  overflow: hidden;
   flex-direction: column;
  .van-sticky {
    left: auto;
    right: auto;
  }

  .left {
    margin-left: 40px;
    width: 300px;
    overflow: hidden;
    margin-top: 20px;
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;

    .classify-title {
      font-size: 20px;
      padding: 0 20px 0;
      line-height: 1;
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img{
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    width: 860rpx;
    margin: 40px auto;
  }

  .card {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
      .tip{
        position: absolute;
        top:20px;
        left:20px;
        z-index: 1;
        img{
          width: 50px;
          // height: 35px;
        }
      }
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      width: 100%;

      .cover {
        flex: 0 0 280px;
        margin-right: 20px;
        width: 280px;
        height: 136px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;

        .text {
          font-size: 14px;
          color: #666;
          overflow: hidden;
          display: -webkit-box;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 5;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        .more {
          font-size: 14px;
          color: #175199;
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      .handle {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 120px;
      }

      .btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 40px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 30px;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    margin-bottom: 20px;

    .type-btn {
      width: 140px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 20px;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .active {
      color: #fff;
      background: #a40001;
    }
  }

  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 20px;
      color: #333;
      margin-left: 10px;
    }
  }

  .idea,
  .cert {

    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .idea-nav-item {
      width: calc((100% - 200px) / 7);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 30px;
      margin-right: 20px;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 8px 8px 0 0;
        box-sizing: border-box;
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
}
</style>

<style lang="scss">
</style>
